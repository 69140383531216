<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import FooterSection from "/src/components/FooterSection.vue";
import HeaderSection from "/src/components/HeaderSection.vue";
import ProjectElement from "./../ProjectElement.vue";
import { onMounted } from "vue";
import AOS from "aos";
import TeamElement from "./../TeamElement.vue";

onMounted(() => {
  AOS.init();
});
const filterProjects = ref("Property");
const jobs = ref(clientConfig.content.jobs);

// const firstIsActive = ref(false);
// const secondIsActive = ref(false);
// const thirdIsActive = ref(false);

const filterDivision = ref("Property");
const people = ref(clientConfig.content.people);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="PropertyInsurance" class="product-page-layout">
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <header class="d-flex">
            <h2>
              <b>Property</b><br />
              Insurance
            </h2>
            <img src="@/assets/img/pen/property.png" />
          </header>
          <p class="highlight main-text pb-5 pt-2">
            Our team of Property experts are dedicated to working with you to
            provide effective solutions and outstanding service. Experience Pen
            Underwriting’s unmatched expertise in providing tailored coverage
            and competitive pricing for complex medium-hazard risks.
            <br /><br />
            We offer capacity up to $35 million on a 100% lead or follow basis.
          </p>

          <h3 class="highlight">Industries & Occupations</h3>

          <section class="industry">
            <div class="industry-list row justify-content-left">
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Manufacturing</h5>
                  <ul>
                    <li>Food and Beverage</li>
                    <li>Machinery and Equipment</li>
                    <li>Metal Products</li>
                    <li>Plastics and Rubber, fibreglassing</li>
                    <li>Petroleum, Chemical & Associated Product</li>
                    <li>Wood and Paper Product</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Hospitality & Accommodation</h5>
                  <ul>
                    <li>Accommodation including boarding houses</li>
                    <li>Backpackers</li>
                    <li>Pubs</li>
                    <li>Cafes & Restaurants</li>
                    <li>Nightclubs</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Wholesale Trade</h5>
                  <ul>
                    <li>Basic Material</li>
                    <li>Personal &amp; Household Goods</li>
                    <li>Machinery</li>
                    <li>Motor Vehicle Parts & services</li>
                  </ul>
                </div>
              </div>

              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Property Owners</h5>
                  <ul>
                    <li>Property owners of single and multi-tenanted risks</li>
                    <li>Vacant properties</li>
                    <li>Mixed properties including Hospitality</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Transport & Storage</h5>
                  <ul>
                    <li>Storage</li>
                    <li>Services to Transport</li>
                    <li>Warehousing</li>
                  </ul>
                </div>
              </div>
              <!-- <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Personal & Other Services</h5>
                  <ul>
                    <li>Personal & Other Services</li>
                  </ul>
                </div>
              </div> -->

              <!-- <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Retail Trade</h5>
                  <ul>
                    <li>Motor Vehicle Retailing and Services</li>
                    <li>Retail outlets</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Personal & Other Services</h5>
                  <ul>
                    <li>Property Services</li>
                    <li>Business Services</li>
                  </ul>
                </div>
              </div>-->
              <!-- <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">
                    Mixed Properties including hospitality
                  </h5>
                </div>
              </div> -->
            </div>
            <hr />
            <div class="row industry-list exclusion">
              <div class="card col industry-card exclusion">
                <div class="card-body p-3">
                  <h3 class="highlight card-title mt-4">Outside appetite:</h3>
                  <ul>
                    <li>Mining</li>
                    <li>Wreckers</li>
                    <li>Island Risks</li>
                    <li>Residential risks</li>
                    <li>Outlaw club houses</li>
                    <li>100% Timber risks</li>
                    <li>Transmission and Distribution lines</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <h3 class="highlight">Proposal Forms</h3>
          <a
            class="btn btn-primary"
            href="https://qrco.de/be0vQX"
            target="_blank"
            >Proposal PDFs (Property)</a
          >

          <section>
            <h3 class="highlight my-4">Our Underwriters</h3>
            <div class="underwriters-wrapper row xs-justify-content-center">
              <TeamElement
                v-for="member in people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="member.division == filterDivision"
              />
            </div>
          </section>

          <section class="projects section-bg">
            <h3>Recent Wins</h3>
            <div class="recent-wins">
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects"
                />
              </div>
            </div>
          </section>

          <!-- <div class="testimonials">
            <div
              class="card p-0"
              :class="{ active: firstIsActive }"
              @mouseover="firstIsActive = true"
              @mouseleave="firstIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="firstIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: secondIsActive }"
              @mouseover="secondIsActive = true"
              @mouseleave="secondIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="secondIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: thirdIsActive }"
              @mouseover="thirdIsActive = true"
              @mouseleave="thirdIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="thirdIsActive">Read More</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
