<script setup>
import { ref } from "vue";
import { useElementVisibility } from "@vueuse/core";
import clientConfig from "/src/config/client";
// import "bootstrap";
// import PDFElement from "./PDFElement.vue";
// import ProjectElement from "./ProjectElement.vue";
// import TeamMember from "./TeamMember.vue";
import FooterSection from "./FooterSection.vue";
import DropdownMenu from "./DropdownMenu.vue";

import { onMounted } from "vue";
import AOS from "aos";

// import "vue3-carousel/dist/carousel.css";
// import { Carousel, Slide, Navigation } from "vue3-carousel";

onMounted(() => {
  AOS.init();
});

// const filterProjects = ref("info");
const showModal = ref(false);
const productList = ref(clientConfig.content.products);
// const teamMembers = ref(clientConfig.content.teamMembers);

// const home = ref(null);
// const scrolledToHome = useElementVisibility(home);
const products = ref(null);
const scrolledToProducts = useElementVisibility(products);
// const superscore = ref(null);
// const scrolledToSuperscore = useElementVisibility(superscore);
// const calculators = ref(null);
// const scrolledToCalculators = useElementVisibility(calculators);
// const contacts = ref(null);
// const scrolledToContacts = useElementVisibility(contacts);

// const slides = [
//   {
//     id: "1",
//     title: "Growing Partnership",
//     subheading: "with our people",
//     content:
//       "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est eopksio laborum. Sed ut perspiciatis unde omnis istpoe natus error sit voluptatem accusantium doloremque eopsloi laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunot explicabo.",
//     imageUrl: "apple-touch-icon",
//   },
//   {
//     id: "2",
//     title: "Vue 3 Components",
//     subheading: "subheading",
//     content: "Know the components",
//     imageUrl: "apple-touch-icon",
//   },
//   {
//     id: "3",
//     title: "Vue 3 Conditional",
//     subheading: "subheading",
//     content: "Rendering Conditionally",
//     imageUrl: "apple-touch-icon",
//   },
//   {
//     id: "4",
//     title: "Vue 3 Reactivity",
//     subheading: "subheading",
//     content: "VueJS is Reactive",
//     imageUrl: "apple-touch-icon",
//   },
//   {
//     id: "5",
//     title: "Vue 3 Compute",
//     subheading: "subheading",
//     content: "VueJS uses computed properties",
//     imageUrl: "apple-touch-icon",
//   },
// ];
</script>

<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="home" ref="home">
    <DropdownMenu />
    <div class="container">
      <div class="row">
        <div
          class="col-lg-8 order-1 order-lg-1 hero-img pl-5"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div class="pen_logo"></div>
          <h2>
            Insurance that <br /><span class="cursive">Evolves </span>with you
          </h2>
          <h3 class="highlight">
            Partnering with you to deliver innovative insurance solutions
          </h3>
        </div>
        <div
          class="col-lg-4 flex-column justify-content-top pt-4 pt-lg-0 order-2 order-lg-2 mt-5 d-flex gap-4 mx-auto"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <router-link
            to="/#products"
            class="quick-link scrollto"
            :class="scrolledToProducts ? 'active' : ''"
          >
            <h4>Products & Services</h4>
            <p>We help you grow your business</p>
          </router-link>
          <router-link to="/team" class="quick-link scrollto">
            <h4>The Team</h4>
            <p>Our people are our strength</p>
          </router-link>
          <router-link to="/recent-wins" class="quick-link scrollto">
            <h4>Recent Wins</h4>
            <p>See what we’ve been up to</p>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->
  <main id="">
    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-left">
        <div class="row actions">
          <div class="col text-center">
            <span class="cursive">Protecting</span> Businesses
          </div>
          <span class="d-none d-sm-block">|</span>
          <div class="col text-center">
            Growth by <span class="cursive">Partnerships</span>
          </div>
          <span class="d-none d-sm-block">|</span>
          <div class="col text-center">
            Innovative <span class="cursive">Solutions</span>
          </div>
        </div>

        <!-- <div class="side-banner">
          <p>The Power of Pen:</p>
          <h2>Giving you<br />the <span class="cursive">Edge</span></h2>
        </div>

        <Carousel>
          <Slide v-for="slide in slides" :key="slide.id">
            <div class="carousel__item">
              <div class="row content px-5">
                <div class="col-lg-4">
                  <img
                    :src="require(`@/assets/img/${slide.imageUrl}.png`)"
                    alt=""
                  />
                </div>
                <div class="col-lg-4 title">
                  <h3 class="highlight mb-0">{{ slide.title }}</h3>
                  <h3>{{ slide.subheading }}</h3>
                  <p class="mb-0">LOREM IPSUM DOLOR AMET CONSECTETUER</p>
                  <a href="">Meet the team</a>
                </div>
                <div class="col-lg-4 content">
                  <p>{{ slide.content }}</p>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel> -->
      </div>
    </section>
    <!-- End About Us Section -->

    <!-- ======= Products & Services Section ======= -->
    <section id="products" class="section products" ref="products">
      <div class="container text-center" data-aos="zoom-in">
        <h3 class="highlight text-center">Products & Services</h3>
        <div class="row xs-justify-content-center g-3 product-wrapper">
          <div
            class="col-5 col-md-4"
            v-for="product in productList"
            :key="product.id"
          >
            <div class="card">
              <a class="card-body" :href="`#${product.clickUrl}`">
                <img
                  :src="require(`@/assets/img/pen/${product.imageUrl}.png`)"
                  alt=""
                />
                <h4 class="card-title mt-4 highlight">{{ product.title }}</h4>
              </a>
            </div>
          </div>
        </div>
        <!-- <router-link to="/products-and-services" class="highlight">
          <button>Explore more</button>
        </router-link> -->
      </div>
    </section>
    <!-- End Superscore Section -->
  </main>
  <!-- End #main -->

  <FooterSection />

  <a href="#" class="back-to-top"><i class="ri-arrow-up-line"></i></a>
  <!-- <div id="preloader"></div> -->
  <div :class="showModal ? 'modal-backdrop show' : ''"></div>
</template>
