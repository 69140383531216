<template>
  <footer id="footer" ref="contacts">
    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright
        <span>Pen Underwriting {{ new Date().getFullYear() }}</span>
        <span class="px-3">|</span>
        <span> Pty Ltd ABN 89 113 929 516 AFSL 290518</span>
      </div>

      <div class="credits">
        <router-link to="/contact-us">CONTACT</router-link>
        <!-- <span class="px-2"> | </span> -->
        <!-- <router-link to="">PRIVACY POLICY</router-link>
        <span class="px-2"> | </span>
        <router-link to="">CONFERENCES</router-link> -->
      </div>
    </div>
  </footer>
</template>
