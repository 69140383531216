<script setup>
import { ref } from "vue";
// import { useElementVisibility } from "@vueuse/core";
// import AppAccordion from "./AppAccordion";
import clientConfig from "/src/config/client";
import ProjectElement from "./ProjectElement.vue";
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

// const FAQ = ref(null);
const filterProjects = ref("Property");
// const scrolledToFAQ = useElementVisibility(FAQ);
// const contacts = ref(null);
// const scrolledToContacts = useElementVisibility(contacts);
// const jobs = ref(clientConfig.content.jobs);
// const teamMembers = ref(clientConfig.content.teamMembers);
const jobs = ref(clientConfig.content.jobs);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="recent-wins" class="section recent-wins">
      <div class="container" data-aos="zoom-in">
        <h2>Recent <span class="cursive">Wins</span></h2>
        <p class="highlight py-5">
          Explore some of the recent wins across our business.
        </p>
      </div>
      <!-- CLARIFY NEXT SECTION -->

      <!-- ======= Projects Section ======= -->
      <div id="projects" ref="projects" class="projects section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title p-0">
            <h2>Filters</h2>
          </div>
          <div class="filter-button">
            <div class="buttons-wrapper row justify-content-center">
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2"
                :class="filterProjects == 'All' ? 'filter-active' : ''"
                @click="filterProjects = 'All'"
              >
                All
              </button>
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 property"
                :class="filterProjects == 'Property' ? 'filter-active' : ''"
                @click="filterProjects = 'Property'"
              >
                <span class="badge"></span>Property
              </button>
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 transport"
                :class="filterProjects == 'Transport' ? 'filter-active' : ''"
                @click="filterProjects = 'Transport'"
              >
                <span class="badge"></span>Transport
              </button>
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 liability"
                :class="filterProjects == 'Liability' ? 'filter-active' : ''"
                @click="filterProjects = 'Liability'"
              >
                <span class="badge"></span>Liability
              </button>
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 hospitality"
                :class="filterProjects == 'Hospitality' ? 'filter-active' : ''"
                @click="filterProjects = 'Hospitality'"
              >
                <span class="badge"></span>Hospitality Liability
              </button>

              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 care"
                :class="filterProjects == 'Care' ? 'filter-active' : ''"
                @click="filterProjects = 'Care'"
              >
                <span class="badge"></span>Care Liability
              </button>
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 construction"
                :class="filterProjects == 'Construction' ? 'filter-active' : ''"
                @click="filterProjects = 'Construction'"
              >
                <span class="badge"></span>Construction
              </button>
              <button
                class="col-5 col-sm-4 col-md-3 col-xl-2 professional"
                :class="filterProjects == 'Professional' ? 'filter-active' : ''"
                @click="filterProjects = 'Professional'"
              >
                <span class="badge"></span>Financial Lines
              </button>
            </div>
          </div>

          <div
            class="project-container mt-2"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <!-- <ProjectElement
            v-for="job in jobs"
            :key="job"
            :title="job.title"
            :description="job.description"
            :type="job.type"
            :url="job.url"
            :externalUrl="job.externalUrl"
            v-show="job.type == filterProjects"
          /> -->
            <div
              v-if="filterProjects != 'All'"
              class="row justify-content-center"
            >
              <ProjectElement
                v-for="job in jobs"
                :key="job"
                :title="job.title"
                :type="job.type"
                v-show="job.type == filterProjects"
              />
            </div>
            <div v-else class="row justify-content-center">
              <ProjectElement
                v-for="job in jobs"
                :key="job"
                :title="job.title"
                :type="job.type"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End projects Section -->
    </section>
  </main>

  <FooterSection />
</template>
