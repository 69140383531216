import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import HomePage from "../components/HomePage.vue";
import Team from "../components/TeamPage.vue";
import ProductsAndServices from "../components/ProductsAndServices.vue";
import RecentWins from "../components/RecentWins.vue";
import AboutUs from "../components/AboutUs.vue";
import ContactUs from "../components/ContactUs.vue";

import PropertyInsurance from "../components/products/PropertyInsurance.vue";
import GeneralLiability from "../components/products/GeneralLiability.vue";
import HospitalityLiability from "../components/products/HospitalityLiability.vue";
import CareLiability from "../components/products/CareLiability.vue";
import ConstructionInsurance from "../components/products/ConstructionInsurance.vue";
import ProfessionalRisks from "../components/products/ProfessionalRisks.vue";
import TransportInsurance from "../components/products/TransportInsurance.vue";
import CyberInsurance from "../components/products/CyberInsurance.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/team",
    name: "team",
    component: Team,
  },
  {
    path: "/products-and-services",
    name: "products-and-services",
    component: ProductsAndServices,
  },
  {
    path: "/recent-wins",
    name: "recent-wins",
    component: RecentWins,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/property-insurance",
    name: "property-insurance",
    component: PropertyInsurance,
  },
  {
    path: "/general-liability",
    name: "general-liability",
    component: GeneralLiability,
  },
  {
    path: "/hospitality-liability",
    name: "hospitality-liability",
    component: HospitalityLiability,
  },
  {
    path: "/care-liability",
    name: "care-liability",
    component: CareLiability,
  },
  {
    path: "/construction-insurance",
    name: "construction-insurance",
    component: ConstructionInsurance,
  },
  {
    path: "/professional-risks",
    name: "professional-risks",
    component: ProfessionalRisks,
  },
  {
    path: "/transport-insurance",
    name: "transport-insurance",
    component: TransportInsurance,
  },
  {
    path: "/cyber-insurance",
    name: "cyber-insurance",
    component: CyberInsurance,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ "../views/AboutView.vue");
  //   },
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      return new Promise((resolve) => {
        resolve({ top: 0, left: 0 });
      });
    }
  },
});

export default router;
