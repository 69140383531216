<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import FooterSection from "/src/components/FooterSection.vue";
import HeaderSection from "/src/components/HeaderSection.vue";
import ProjectElement from "./../ProjectElement.vue";
import { onMounted } from "vue";
import AOS from "aos";
import TeamElement from "./../TeamElement.vue";

onMounted(() => {
  AOS.init();
});
const filterProjects = ref("Construction");
const jobs = ref(clientConfig.content.jobs);

// const firstIsActive = ref(false);
// const secondIsActive = ref(false);
// const thirdIsActive = ref(false);

const filterDivision = ref("Construction");
const people = ref(clientConfig.content.people);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="ConstructionInsurance" class="product-page-layout">
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <header class="d-flex">
            <h2>
              <b>Construction</b><br />
              Insurance
            </h2>
            <img src="@/assets/img/pen/construction.png" />
          </header>
          <p class="highlight main-text pb-5 pt-2">
            Our highly experienced construction team are keen to help our
            brokers place the best cover for their client. We offer single &
            annual policies, with limits up to $30m, contract values of up to
            $20m, and turnover for annuals up to $70m.
          </p>

          <!-- <h3 class="highlight">Industries & Occupations</h3> -->

          <section class="industry my-4">
            <div class="industry-list row justify-content-left">
              <div class="card col-12 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Building</h5>
                  <ul>
                    <li>Commercial Developments</li>
                    <li>Industrial Developments</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Civil</h5>
                  <ul>
                    <li>Site preparation</li>
                    <li>Sub-divisions</li>
                    <li>Infrastructure</li>
                    <li>Roads & bridges</li>
                    <li>Transportation</li>
                    <li>Harbour works</li>
                    <li>Mining</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Erection</h5>
                  <ul>
                    <li>Structural steel</li>
                    <li>Mechanical installations</li>
                    <li>Pipelines</li>
                    <li>Renewable power</li>
                  </ul>
                </div>
              </div>
            </div>

            <hr />
            <div class="row industry-list">
              <div class="card col industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Section 2 Liability</h5>
                  <ul>
                    <li>
                      We can offer Section 2 Liability up to $20m on most risks
                      across building, civil and erection (up to 5 storeys
                      outside the CBD)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 class="highlight my-4">Our Underwriters</h3>
            <div class="underwriters-wrapper row xs-justify-content-center">
              <TeamElement
                v-for="member in people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="member.division == filterDivision"
              />
            </div>
          </section>

          <section class="projects section-bg">
            <h3>Recent Wins</h3>
            <div class="recent-wins">
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects"
                />
              </div>
            </div>
          </section>

          <!-- <div class="testimonials">
            <div
              class="card p-0"
              :class="{ active: firstIsActive }"
              @mouseover="firstIsActive = true"
              @mouseleave="firstIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="firstIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: secondIsActive }"
              @mouseover="secondIsActive = true"
              @mouseleave="secondIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="secondIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: thirdIsActive }"
              @mouseover="thirdIsActive = true"
              @mouseleave="thirdIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="thirdIsActive">Read More</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
