<script setup>
// import { ref } from "vue";

import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="contact-us" class="section contact">
      <div class="container" data-aos="zoom-in">
        <h2>Contact <span class="cursive">Us</span></h2>
        <!-- <p class="highlight py-5">
          Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
          eiusmod tempor incididunt ut labore et dolore roipi magna aliqua.
          Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
        </p> -->
        <div class="big-cards-wrapper mt-4">
          <div class="card unit">
            <a href="https://maps.app.goo.gl/LB3c44R6atNLiQer7" target="_blank">
              <img
                class="card-img-top"
                src="@/assets/img/pen/sydney.jpg"
                alt="Card image cap"
            /></a>
            <div class="card-body">
              <h5 class="card-title highlight">
                Sydney<span> - HEAD OFFICE</span>
              </h5>
              <p class="card-text">
                Level 25, 45 Clarence Street<br />
                Sydney NSW 2000<br />
                Australia
              </p>
              <p class="card-text">
                <b>Tel:</b> <a href="tel:+61293235000">+61 2 9323 5000</a><br />
                <b>Email: </b>
                <a href="mailto:info.au@penunderwriting.com"
                  >info.au@penunderwriting.com</a
                >
              </p>
            </div>
          </div>
          <div class="card unit">
            <a href="https://maps.app.goo.gl/xtVd8HRjMUqhdegt6" target="_blank">
              <img
                class="card-img-top"
                src="@/assets/img/pen/brisbane.jpg"
                alt="Card image cap"
            /></a>
            <div class="card-body">
              <h5 class="card-title highlight">Brisbane</h5>
              <p class="card-text">
                Level 12, 157 Ann Street<br />
                Brisbane QLD 4000<br />
                Australia
              </p>
              <p class="card-text">
                <b>Tel:</b> <a href="tel:+61730561400">+61 7 3056 1400</a><br />
                <b>Email: </b>
                <a href="mailto:info.au@penunderwriting.com"
                  >info.au@penunderwriting.com</a
                >
              </p>
            </div>
          </div>
          <div class="card unit">
            <a href="https://maps.app.goo.gl/YwbZ6UEawzRiB8u1A" target="_blank">
              <img
                class="card-img-top"
                src="@/assets/img/pen/melbourne.jpg"
                alt="Card image cap"
              />
            </a>
            <div class="card-body">
              <h5 class="card-title highlight">Melbourne</h5>
              <p class="card-text">
                Level 3, 333 Collins Street<br />
                Melbourne VIC 3000<br />
                Australia
              </p>
              <p class="card-text">
                <b>Tel:</b> <a href="tel:+61398100600">+61 3 9810 0600</a><br />
                <b>Email: </b>
                <a href="mailto:info.au@penunderwriting.com"
                  >info.au@penunderwriting.com</a
                >
              </p>
            </div>
          </div>
          <div class="card unit">
            <a href="mailto:claims.au@penunderwriting.com" target="_blank">
              <img
                class="card-img-top"
                src="@/assets/img/pen/claims-map.jpg"
                alt="Card image cap"
              />
            </a>
            <div class="card-body">
              <h5 class="card-title highlight">Claims</h5>

              <p class="card-text">
                <b>Tel:</b> <a href="tel:+61293235000">+61 2 9323 5000</a><br />
                <b>Email: </b>
                <a href="mailto:claims.au@penunderwriting.com"
                  >claims.au@penunderwriting.com</a
                >
              </p>
            </div>
          </div>
        </div>

        <div class="card unit mt-4">
          <div class="card-body">
            <h4 class="card-title highlight">Media enquiries</h4>

            <p class="card-text">
              For all media enquiries please contact<br />
              <b>Violeta Fernando</b>
            </p>
            <p class="card-text">
              <b>Email:</b><br />
              <a href="mailto:violeta_fernando@penunderwriting.com"
                >violeta_fernando@penunderwriting.com</a
              >
            </p>
          </div>
        </div>

        <div
          class="justify-content-top pt-4 pt-lg-0 order-2 order-lg-2 mt-5 gap-4 quick-link-wrapper"
        >
          <!-- <router-link
            to="/products-and-services"
            class="quick-link bottom scrollto"
          >
            <h4>Products & Services</h4>
            <p>We help you grow your business</p>
          </router-link> -->
          <router-link to="/team" class="quick-link bottom scrollto">
            <h4>The Team</h4>
            <p>Our people are our strength</p>
          </router-link>
          <router-link to="/recent-wins" class="quick-link bottom scrollto">
            <h4>Recent Wins</h4>
            <p>See what we’ve been up to</p>
          </router-link>
        </div>
      </div>
      <!-- CLARIFY NEXT SECTION -->
    </section>
  </main>

  <FooterSection />
</template>
