<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import FooterSection from "/src/components/FooterSection.vue";
import HeaderSection from "/src/components/HeaderSection.vue";
import ProjectElement from "./../ProjectElement.vue";
import { onMounted } from "vue";
import AOS from "aos";
import TeamElement from "./../TeamElement.vue";

onMounted(() => {
  AOS.init();
});
const filterProjects = ref("Transport");
const jobs = ref(clientConfig.content.jobs);

// const firstIsActive = ref(false);
// const secondIsActive = ref(false);
// const thirdIsActive = ref(false);

const filterDivision = ref("Transport");
const people = ref(clientConfig.content.people);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="TransportInsurance" class="product-page-layout">
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <header class="d-flex">
            <h2>
              <b>Transport</b><br />
              Insurance
            </h2>
            <img src="@/assets/img/pen/transport.png" />
          </header>
          <p class="highlight main-text pb-5 pt-2">
            Our Transport team brings expertise and quality underwriting to
            ensure you receive the best possible coverage options. Whether it's
            hire cars or commercial long-haul fleets, the Pen Transport team
            offers standard, nonstandard, and specialty commercial motor
            insurance tailored to your clients’ needs.
          </p>

          <h3 class="highlight">Industries & Occupations</h3>

          <section class="industry">
            <div class="industry-list row justify-content-left">
              <div class="card col-12 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Coverage</h5>
                  <ul>
                    <li>Agreed value option available</li>
                    <li>
                      Up to $750k per vehicle combination for heavy vehicles
                    </li>
                    <li>Legal Liability up to $30m</li>
                    <li>Extensions available up to $5m for Dangerous Goods</li>
                    <li>Choice of repairer</li>
                    <li>
                      2 year new for old replacement for vehicles &gt; 3.5
                      tonnes (total loss only)
                    </li>
                    <li>Personal Effects / Driver property $2,500</li>
                    <li>Up to $5,000 signwriting</li>
                    <li>
                      Excess waived for sedans and light vehicles not at fault
                    </li>
                  </ul>

                  <h5 class="card-title mt-4">What’s in appetite</h5>
                  <ul>
                    <li>Heavy vehicle owner driver operations</li>
                    <li>Heavy vehicle fleets</li>
                    <li>Small to large light commercial fleets</li>
                    <li>Goods carrying vehicles</li>
                    <li>Hire vehicles</li>
                    <li>We will consider new ventures</li>
                    <li>
                      Mixed fleets including trucks, machinery and commercial
                      vehicles across a range of industries including
                      manufacturing, construction, logistics, trades
                    </li>
                    <li>Couriers (minimum 10 units)</li>
                  </ul>

                  <h5 class="card-title mt-4">What’s not</h5>
                  <ul>
                    <li>Taxi or ride share</li>
                    <li>Limos</li>
                    <li>Livestock cartage</li>
                    <li>Logging</li>
                    <li>Bulk Dangerous Goods</li>
                    <li>Long haul refrigerated</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 class="highlight my-4">Our Underwriters</h3>
            <div class="underwriters-wrapper row xs-justify-content-center">
              <TeamElement
                v-for="member in people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="member.division == filterDivision"
              />
            </div>
          </section>

          <section class="projects section-bg">
            <h3>Recent Wins</h3>
            <div class="recent-wins">
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects"
                />
              </div>
            </div>
          </section>

          <!-- <div class="testimonials">
            <div
              class="card p-0"
              :class="{ active: firstIsActive }"
              @mouseover="firstIsActive = true"
              @mouseleave="firstIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="firstIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: secondIsActive }"
              @mouseover="secondIsActive = true"
              @mouseleave="secondIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="secondIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: thirdIsActive }"
              @mouseover="thirdIsActive = true"
              @mouseleave="thirdIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="thirdIsActive">Read More</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
