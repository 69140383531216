<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import TeamElement from "./TeamElement.vue";
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";
// import _ from "lodash";

onMounted(() => {
  AOS.init();
});

const filterSydney = ref("Sydney");
const filterMelbourne = ref("Melbourne");
const filterBrisbane = ref("Brisbane");
const people = ref(clientConfig.content.people);

const sortedPeople = people;
// const sortedPeople = _.sortBy(people, [function(o) { return o.name; }]);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <!-- ======= Team Section ======= -->
    <section id="team" ref="team" class="team section-bg mt-5">
      <div class="container" data-aos="fade-up">
        <h2>The <span class="cursive">Team</span></h2>
        <p class="highlight py-5">
          Partnering with you to create a first-class underwriting service.
        </p>

        <!-- {{ sortedPeople }} -->

        <h3>Our Insurance <span class="cursive">Underwriters</span></h3>

        <h2 class="highlight my-4">Sydney</h2>
        <div class="underwriters-wrapper row xs-justify-content-center">
          <TeamElement
            v-for="member in sortedPeople"
            :key="member"
            :name="member.name"
            :division="member.division"
            :role="member.role"
            :mobile="member.mobile"
            :mobileUrl="member.mobileUrl"
            :phone="member.phone"
            :phoneUrl="member.phoneUrl"
            :email="member.email"
            :imageUrl="member.imageUrl"
            :location="member.location"
            v-show="member.location == filterSydney"
          />
        </div>

        <h2 class="highlight my-4">Brisbane</h2>
        <div class="underwriters-wrapper row xs-justify-content-center">
          <TeamElement
            v-for="member in sortedPeople"
            :key="member"
            :name="member.name"
            :division="member.division"
            :role="member.role"
            :mobile="member.mobile"
            :mobileUrl="member.mobileUrl"
            :phone="member.phone"
            :phoneUrl="member.phoneUrl"
            :email="member.email"
            :imageUrl="member.imageUrl"
            :location="member.location"
            v-show="member.location == filterBrisbane"
          />
        </div>

        <h2 class="highlight my-4">Melbourne</h2>
        <div class="underwriters-wrapper row xs-justify-content-center">
          <TeamElement
            v-for="member in sortedPeople"
            :key="member"
            :name="member.name"
            :division="member.division"
            :role="member.role"
            :mobile="member.mobile"
            :mobileUrl="member.mobileUrl"
            :phone="member.phone"
            :phoneUrl="member.phoneUrl"
            :email="member.email"
            :imageUrl="member.imageUrl"
            :location="member.location"
            v-show="member.location == filterMelbourne"
          />
        </div>
      </div>
    </section>

    <!-- End Team Section -->
  </main>

  <FooterSection />
</template>
