<script setup>
import { ref } from "vue";

import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

import clientConfig from "/src/config/client";

onMounted(() => {
  AOS.init();
});

const products = ref(clientConfig.content.products);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="products-and-services" class="section products mt-5">
      <div class="container" data-aos="zoom-in">
        <h2>Products & <span class="cursive">Services</span></h2>
        <p class="highlight py-5">
          Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
          eiusmod tempor incididunt ut labore et dolore roipi magna aliqua.
          Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
        </p>
        <div class="row">
          <div class="card" v-for="product in products" :key="product.id">
            <a class="card-body" :href="`/#${product.clickUrl}`">
              <div class="row">
                <div class="col-4 text-center">
                  <img
                    :src="require(`@/assets/img/pen/${product.imageUrl}.png`)"
                    alt=""
                  />
                </div>
                <div class="col-7">
                  <div class="card-text">
                    <h5 class="card-title mt-4 highlight">
                      {{ product.title }}
                    </h5>
                    <p>{{ product.description }}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
