<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import FooterSection from "/src/components/FooterSection.vue";
import HeaderSection from "/src/components/HeaderSection.vue";
import ProjectElement from "./../ProjectElement.vue";
import { onMounted } from "vue";
import AOS from "aos";
import TeamElement from "./../TeamElement.vue";

onMounted(() => {
  AOS.init();
});
const filterProjects = ref("Professional");
const jobs = ref(clientConfig.content.jobs);

// const firstIsActive = ref(false);
// const secondIsActive = ref(false);
// const thirdIsActive = ref(false);

const filterDivision = ref("Financial Lines");
const people = ref(clientConfig.content.people);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="professional-risks-insurance" class="product-page-layout">
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <header class="d-flex">
            <h2>
              <b>Financial</b><br />
              Lines
            </h2>
            <img src="@/assets/img/pen/professional-indemnity.png" />
          </header>
          <p class="highlight main-text pb-5 pt-2">
            Our Financial Lines team is dedicated to delivering solutions for a
            wide range of occupations, working closely with you to meet your
            specific needs. We are committed to exceptional customer service and
            take pride in understanding your clients’ business. Our experienced
            professionals focus on crafting insurance solutions that are
            perfectly suited to your unique requirements.
          </p>

          <!-- <h3 class="highlight">Professional Indemnity - Excess of Loss</h3> -->

          <section class="industry">
            <div class="industry-list row justify-content-left">
              <div class="card col-md-6 industry-card">
                <div class="card-body p-3">
                  <h2 class="my-4">Professional Indemnity - Primary</h2>
                  <p>
                    Comprehensive coverage up to $10m capacity, we specialize in
                    serving companies with a fee income up to $5m.<br /><br />However,
                    we are open to considering any fee income, ensuring that
                    businesses of all sizes can benefit from our expertise.
                  </p>
                  <h5>Preferred risks</h5>
                  <ul>
                    <li>Architects</li>
                    <li>Engineers (Civil, Structural)</li>
                    <li>IT Consultants (No Cyber Security or MSP)</li>
                    <li>Project Managers</li>
                    <li>Real Estate</li>
                    <li>Surveyors (Land, Quantity)</li>
                  </ul>
                  <h5>Limited appetite</h5>
                  <ul>
                    <li>Surveyors (Building)</li>
                    <li>Engineers (Chemical, Structural)</li>
                  </ul>
                </div>
              </div>
              <div class="card col-md-6 industry-card">
                <div class="card-body p-3">
                  <h2 class="my-4">Professional Indemnity - Excess</h2>
                  <p>
                    Follow form Wording, with local security, up to up to $20m
                    capacity.
                  </p>
                  <p>
                    We can accommodate any size firm, and we can deploy more
                    capacity than the primary.
                  </p>
                  <h5>Preferred risks</h5>
                  <ul>
                    <li>Construction PI – D&amp;C</li>
                    <li>Mining risks</li>
                    <li>Large engineers</li>
                    <li>Law Firms</li>
                  </ul>
                </div>
              </div>
              <div class="card col-md-6 industry-card">
                <div class="card-body p-3">
                  <h2 class="my-4">D&amp;O</h2>
                  <h5>
                    D&amp;O cover specifically targeted at the mining and
                    resource industry.
                  </h5>
                  <ul>
                    <li>Junior miners, opportunistic, producers</li>
                    <li>Private and listed companies</li>
                    <li>Primary and XOL</li>
                  </ul>
                  <h5>Coal related operations are outside appetite</h5>
                </div>
              </div>
            </div>

            <hr />

            <!-- <h3 class="highlight">Cyber</h3> -->
            <h2 class="my-4">Cyber</h2>
            <p class="highlight">
              <b
                >Broad appetite and coverage for businesses up to 450m
                turnover</b
              >
            </p>
            <div class="industry-list row justify-content-left">
              <div class="card col-md-6 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Coverage</h5>
                  <ul>
                    <li>Breach response costs</li>
                    <li>Cyber Business Interruption</li>
                    <li>Extortion and Ransomware</li>
                    <li>Privacy Liability/Network Security</li>
                    <li>Cyber Crime</li>
                    <li>Credit monitoring/Identity Theft</li>
                    <li>Public relations expenses</li>
                  </ul>
                </div>
              </div>
              <div class="card col-md-6 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Preferred risks</h5>
                  <ul>
                    <li>Architects &amp; Engineers</li>
                    <li>Charities &amp; Not for profit</li>
                    <li>Construction</li>
                    <li>Domestic Services</li>
                    <li>Education</li>
                    <li>Financial Institutions</li>
                    <li>Government</li>
                    <li>Hospitality</li>
                    <li>Logistics &amp; Freight Forwarder</li>
                    <li>Manufacturing</li>
                    <li>Real Estate</li>
                    <li>Recruitment</li>
                    <li>Retail &amp; Wholesale</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <hr />
            <div class="row industry-list exclusion">
              <div class="card col col-md-4 industry-card">
                <div class="card-body p-3">
                  <h3 class="highlight card-title mt-4">Outside appetite:</h3>
                  <ul>
                    <li>Valuers</li>
                    <li>Business / Mortgage Brokers</li>
                    <li>Corporate Advisors / M&A</li>
                  </ul>
                </div>
              </div>
            </div> -->
          </section>

          <section>
            <h3 class="highlight my-4">Our Underwriters</h3>
            <div class="underwriters-wrapper row xs-justify-content-center">
              <TeamElement
                v-for="member in people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="member.division == filterDivision"
              />
            </div>
          </section>

          <section class="projects section-bg">
            <h3>Recent Wins</h3>
            <div class="recent-wins">
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects"
                />
              </div>
            </div>
          </section>

          <!-- <div class="testimonials">
            <div
              class="card p-0"
              :class="{ active: firstIsActive }"
              @mouseover="firstIsActive = true"
              @mouseleave="firstIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="firstIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: secondIsActive }"
              @mouseover="secondIsActive = true"
              @mouseleave="secondIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="secondIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: thirdIsActive }"
              @mouseover="thirdIsActive = true"
              @mouseleave="thirdIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="thirdIsActive">Read More</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
