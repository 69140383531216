<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="about-us" class="section about">
      <div class="container" data-aos="zoom-in">
        <h2>About <b>Pen Underwriting</b></h2>
        <div class="row">
          <div class="col-md-8">
            <p class="highlight py-5">
              Pen Underwriting is a well established, customer-centric agency
              made up of a tight knit community of commercial insurance
              underwriters. We are dedicated to delivering sophisticated
              solutions to our broker partners across Australia.
            </p>

            <p>
              The security behind all our products is provided by a range of
              international and domestic insurance and reinsurance companies.
              Each insurance partner has a strong security rating. We strive for
              long term relationships and recognise we must meet and exceed the
              requirements of every stakeholder.
            </p>
            <p class="my-5">
              We take pride in our technical capabilities and collectively have
              in excess of 1,000 years of experience across our teams. What sets
              us apart from others in the market is the service we provide to
              our brokers and our ability to craft solutions for even the most
              complex risks across a broad range of classes:
            </p>
            <h3 class="highlight text-center my-5">
              Property • Liability • Construction • Transport • Financial Lines
            </h3>
            <!-- <div class="about-description mb-5">

            </div>
            <div class="about-extra-description">

            </div> -->
          </div>
          <div class="col-md-3">
            <ul class="partner-logos">
              <li>
                <img src="@/assets/img/pen/Lloyds_logo.png" alt="Lloyd's" />
              </li>
              <li>
                <img src="@/assets/img/pen/Swiss_Re_logo.png" alt="Swiss Re" />
              </li>
              <li>
                <img
                  src="@/assets/img/pen/BerkleyRe_logo.png"
                  alt="Berkley Re"
                />
              </li>
              <li>
                <img src="@/assets/img/pen/HDI_logo.svg" alt="HDI" />
              </li>
              <li>
                <img src="@/assets/img/pen/AXA_logo.svg" alt="AXA" />
              </li>
              <li>
                <img
                  src="@/assets/img/pen/Allied_World_logo.svg"
                  alt="Allied World"
                />
              </li>
            </ul>
          </div>
        </div>

        <section>
          <h2 class="pb-4">Executive Team</h2>
          <div class="underwriters-wrapper row justify-content-left mt-4">
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Ken-Keenan.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Ken Keenan</h5>
                <p class="division mb-2">Chief Executive</p>
                <p class="m-0"><a href="tel:0466469849">0466 469 849</a></p>
                <p class="m-0">
                  <a href="mailto:ken_keenan@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Alisa-Martins.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Alisa Martins</h5>
                <p class="division mb-2">Chief Operating Officer</p>
                <p class="m-0"><a href="tel:0409458020">0409 458 020</a></p>
                <p class="m-0">
                  <a href="mailto:Alisa_Martins@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Lenu-Lukose.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Lenu Lukose</h5>
                <p class="division mb-2">Chief Underwriting Officer</p>
                <p class="m-0"><a href="tel:0401200925">0401 200 925</a></p>
                <p class="m-0">
                  <a href="mailto:lenu_lukose@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Jean-Leighton.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Jean Leighton</h5>
                <p class="division mb-2">Chief Financial Officer</p>
                <p class="m-0"><a href="tel:0418757275">0418 757 275</a></p>
                <p class="m-0">
                  <a href="mailto:Jean_Leighton@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Hun-Kim.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Hun Kim</h5>
                <p class="division mb-2">Chief Actuary</p>
                <p class="m-0"><a href="tel:0478259602">0478 259 602</a></p>
                <p class="m-0">
                  <a href="mailto:hun_kim@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 class="pb-4">Leadership Team</h2>
          <div class="underwriters-wrapper row justify-content-left mt-4">
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Nick-Best.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Nick Best</h5>
                <p class="division mb-2">Property Team Manager</p>
                <p class="m-0"><a href="tel:0479174406">0479 174 406</a></p>
                <p class="m-0">
                  <a href="mailto:nick_best@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Paul-Behm.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Paul Behm</h5>
                <p class="division mb-2">Liability Team Manager</p>
                <p class="m-0"><a href="tel:0414950122">0414 950 122</a></p>
                <p class="m-0">
                  <a href="mailto:paul_behm@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Kerrie-Coxell.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Kerrie Coxell</h5>
                <p class="division mb-2">Care Liability Manager</p>
                <p class="m-0"><a href="tel:0412837190">0412 837 190</a></p>
                <p class="m-0">
                  <a href="mailto:kerrie_coxell@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Dean-West.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Dean West</h5>
                <p class="division mb-2">
                  National Construction & Engineering Manager
                </p>
                <p class="m-0"><a href="tel:0468542032">0468 542 032</a></p>
                <p class="m-0">
                  <a href="mailto:dean_west@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Tim-Moore.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Tim Moore</h5>
                <p class="division mb-2">Transport Manager</p>
                <p class="m-0"><a href="tel:0417565636">0417 565 636</a></p>
                <p class="m-0">
                  <a href="mailto:Tim_Moore@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>

            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Harry-Zheng.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Harry Zheng</h5>
                <p class="division mb-2">Senior Risk & Compliance Manager</p>
                <p class="m-0"><a href="tel:0466535262">0466 535 262</a></p>
                <p class="m-0">
                  <a href="mailto:harry_zheng@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
            <div class="card underwriters col-5 col-md-2 mr-1">
              <img
                class="card-img-top"
                src="@/assets/img/pen/Paul-Belford.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h5 class="card-title name highlight mb-2">Paul Belford</h5>
                <p class="division mb-2">Technical Underwriting Manager</p>
                <!-- <p class="m-0"><a href="tel:0293235022">02 9323 5022</a></p> -->
                <p class="m-0"><a href="tel:0439333801">0439 333 801</a></p>
                <p class="m-0">
                  <a href="mailto:paul_belford@penunderwriting.com">Email</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div
          class="justify-content-top pt-4 pt-lg-0 order-2 order-lg-2 mt-5 gap-4 quick-link-wrapper"
        >
          <!-- <router-link
            to="/products-and-services"
            class="quick-link bottom scrollto"
          >
            <h4>Products & Services</h4>
            <p>We help you grow your business</p>
          </router-link> -->
          <router-link to="/team" class="quick-link bottom scrollto">
            <h4>The Team</h4>
            <p>Our people are our strength</p>
          </router-link>
          <router-link to="/recent-wins" class="quick-link bottom scrollto">
            <h4>Recent Wins</h4>
            <p>See what we’ve been up to</p>
          </router-link>
        </div>
      </div>
      <!-- CLARIFY NEXT SECTION -->
    </section>
  </main>

  <FooterSection />
</template>
