<script setup>
import { ref } from "vue";
import clientConfig from "/src/config/client";
import FooterSection from "/src/components/FooterSection.vue";
import HeaderSection from "/src/components/HeaderSection.vue";
import ProjectElement from "./../ProjectElement.vue";
import { onMounted } from "vue";
import AOS from "aos";
import TeamElement from "./../TeamElement.vue";

onMounted(() => {
  AOS.init();
});
const filterProjects = ref("Care");
const jobs = ref(clientConfig.content.jobs);

// const firstIsActive = ref(false);
// const secondIsActive = ref(false);
// const thirdIsActive = ref(false);

const filterDivision = ref("Care Liability");
const people = ref(clientConfig.content.people);
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="care-liability-insurance" class="product-page-layout">
      <div class="overlay">
        <div class="container" data-aos="zoom-in">
          <header class="d-flex">
            <h2>
              <b>Care</b><br />
              Liability
            </h2>
            <img src="@/assets/img/pen/care-liability.png" />
          </header>
          <p class="highlight main-text pb-5 pt-2">
            Our Care team is passionate about protecting the businesses that
            care for our most vulnerable. We offer combined liability packages
            designed specifically for Aged Care and Disability Care providers.
            Reach out to our experts to discuss your client’s needs.
          </p>

          <h3 class="highlight">Industries & Occupations</h3>

          <section class="industry">
            <!-- <h5 class="card-title mt-4">
              We provide insurance to those who care for the Aged and people
              with Disabilities such as (but not limited to)
            </h5> -->
            <div class="industry-list row justify-content-left">
              <div class="card col-12 col-md-6 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Aged Care</h5>
                  <ul>
                    <li>Residential Aged Care Facilities</li>
                    <li>Retirement living and Independent living units</li>
                    <li>At Home Nursing Care</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-md-6 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-4">Disability Support Providers</h5>
                  <ul>
                    <li>Personal Care</li>
                    <li>At home nursing care</li>
                    <li>Permanent, short stay disability accommodation</li>
                    <li>Companionship & transportation</li>
                    <li>Australian Disability Enterprises</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div class="row industry-list">
              <div class="card col-12 industry-card exclusion">
                <div class="card-body p-3">
                  <h3 class="highlight card-title mt-4">
                    Our Industry Specific Products:
                  </h3>
                  <ul>
                    <li>
                      General Liability ($50m on Aged Care, $20m on Disability
                      providers)
                    </li>
                    <li>Professional Malpractice Liability ($20m)</li>
                    <li>Management Liability ($20m)</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div class="row industry-list">
              <div class="card col industry-card exclusion">
                <div class="card-body p-3">
                  <h3 class="highlight card-title mt-4">Outside appetite:</h3>
                  <ul>
                    <li>
                      Foster Care services and/or transitional accommodation
                    </li>
                    <li>
                      Permanent Accommodation for disabled persons under 18
                      years of age
                    </li>
                    <li>
                      Providers where there majority of clients are under 18
                      years of age
                    </li>
                    <li>Drug and alcohol rehabilitation centres</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3 class="highlight my-4">Our Underwriters</h3>
            <div class="underwriters-wrapper row xs-justify-content-center">
              <TeamElement
                v-for="member in people"
                :key="member"
                :name="member.name"
                :division="member.division"
                :role="member.role"
                :mobile="member.mobile"
                :mobileUrl="member.mobileUrl"
                :phone="member.phone"
                :phoneUrl="member.phoneUrl"
                :email="member.email"
                :imageUrl="member.imageUrl"
                :location="member.location"
                v-show="member.division == filterDivision"
              />
            </div>
          </section>

          <section class="projects section-bg">
            <h3>Recent Wins</h3>
            <div class="recent-wins">
              <div
                v-if="filterProjects != 'All'"
                class="row xs-justify-content-center"
              >
                <ProjectElement
                  v-for="job in jobs"
                  :key="job"
                  :title="job.title"
                  :type="job.type"
                  v-show="job.type == filterProjects"
                />
              </div>
            </div>
          </section>

          <!-- <div class="testimonials">
            <div
              class="card p-0"
              :class="{ active: firstIsActive }"
              @mouseover="firstIsActive = true"
              @mouseleave="firstIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="firstIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: secondIsActive }"
              @mouseover="secondIsActive = true"
              @mouseleave="secondIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="secondIsActive">Read More</button>
              </div>
            </div>
            <div
              class="card p-0"
              :class="{ active: thirdIsActive }"
              @mouseover="thirdIsActive = true"
              @mouseleave="thirdIsActive = false"
            >
              <div class="card-body p-3">
                <img src="@/assets/img/pen/user.png" alt="" />
                <h5 class="card-title mt-4">Name Surname</h5>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <button v-if="thirdIsActive">Read More</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </main>

  <FooterSection />
</template>
